import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  drawTriangle,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo,
  drawPoint,
  drawTri,
  setSize,
  labelIt,
  drawDash,
  placeEggs,
  createSpace,
  drawSegment,
  drawLine,
  drawArc,
  drawIntersection,
  setColor,
  drawCircle,
  setWidth,
  drawAngle
} from '../Utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var graph =createSpace(-8, 7, -8, 7);
    graph.suspendUpdate();
    // Layering
    graph.options.axis.strokeWidth=0;
    graph.options.layer['image']=17;
    graph.options.layer['text']=16;
    graph.options.layer['line']=16;
    graph.options.layer['point']=17;
    graph.options.layer['glider']=17;
    graph.options.layer['angle']=18;
    graph.options.board.minimizeReflow = 'none';
    graph.options.point.showInfobox =false;
    graph.options.line.highlight=false;
    graph.options.image.highlight=false;
    graph.options.text.highlight=false;
    graph.options.polygon.highlight=false;
//    graph.options.text.display='internal';
    //Make the board responsive
    makeResponsive(graph);
    // Creat titleStyle
    placeLogo(graph);
    placeTitle(graph, 'Drawing an Angle Bisector', '');
////////////////////////////////////////////////////////////////////////////////
    var analytics = placeUndo(graph, 'left');
    analytics.setLabel('Tap to Go to Previous Step');
    analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    analytics.on('over', function () {this.label.setAttribute({visible:true});});
    analytics.on('out', function () {this.label.setAttribute({visible:false});});
    ////////////////////////////////////////////////////
    var erase = placeRedo(graph);
    erase.setLabel('Tap to Go to Next Step')
    erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////////////////////////////////
    var i = 0;
    var eggs = placeEggs(graph);
    eggs[0].setAttribute({visible:()=>i>=0});
    ////////////////
    eggs[1].setAttribute({visible:()=>i>=1});
    ////////////////
    eggs[2].setAttribute({visible:()=>i>=2});
    /////////////////////////////////////////////////////////
    var PtA = drawPoint(graph, -3, 0);   //A
    setSize(graph, PtA, 3);
    labelIt(graph, PtA, 'O');
    PtA.label.setAttribute({offset:[-20, 0]});
///////////////////////////////////////////////
    var PtR = drawPoint(graph, -6, -2);  //R
    setSize(graph, PtR, 3);
    labelIt(graph, PtR, 'R');
    PtR.setAttribute({visible:false});
   ///////////////////////////////////////////////
   var Pt2 = drawPoint(graph, 3,2);  //2
   setSize(graph, Pt2, 3);
   labelIt(graph, Pt2, 'A');
   Pt2.setAttribute({visible:true});
//////////////////////////////////////////////////////
   var Pt3 = drawPoint(graph, 3, -2);  //3
   setSize(graph, Pt3, 3);
   labelIt(graph, Pt3, 'B');
   Pt3.setAttribute({visible:true});
///////////////////////////////////////////////////////
   var ang0 =drawAngle(graph, Pt2, PtA, Pt3);
   ang0.setAttribute({name:'', radius:1});
   //////////////////////////////////
   var circleA =drawCircle(graph, PtA, PtR);
   circleA.setAttribute({visible:false});

   var Ln1 =drawSegment(graph, PtA, Pt2);
   var Ln2 =drawSegment(graph, PtA, Pt3);

   const ptA = {
     name:'',
     size: ()=>Math.round(2*graph.canvasWidth/800.),
     color:'black',
     strokeWidth:()=>Math.ceil(4*graph.canvasWidth/800.),
     highlight:false
   };

   var PtB = graph.create('intersection', [circleA, Ln1, 0], ptA);
   PtB.setAttribute({visible:()=>i>0});
   labelIt(graph, PtB, '1');
   PtB.label.setAttribute({offset:[0, 40]});

   var PtC =graph.create('intersection', [circleA, Ln2, 0], ptA);
   PtC.setAttribute({visible:false});
   labelIt(graph, PtC, '2');
   PtC.label.setAttribute({offset:[0, -10]});

   var mp1 = graph.create('midpoint', [Pt2, PtB]);
   mp1.setAttribute({visible:false});
   var mp2 = graph.create('midpoint', [Pt3, PtC]);
   mp2.setAttribute({visible:false});

   var circle1 =drawCircle(graph, PtB, Pt3);
   circle1.setAttribute({visible:false});

   var circle2 = graph.create('circle', [function() { return [PtC.X(), PtC.Y()];}, function() { return circle1.Radius(); }]);
   circle2.setAttribute({visible:false});

   var Int3 =graph.create('intersection', [circle1, circle2, 1], ptA);
   labelIt(graph, Int3, 'P');
   Int3.label.setAttribute({offset:[20, 0]});
   Int3.setAttribute({visible:()=>i>2});

   var Ln3 =drawSegment(graph, PtA, Int3);
   Ln3.setAttribute({visible:()=>i>3});
   ///////

   var bi1 =graph.create('bisector', [Pt2, PtA, Int3], {visible:false});
   var bi2 =graph.create('bisector', [Pt3, PtA, Int3], {visible:false});

   var pArc1 =graph.create('intersection', [circle1, bi1, 1]);
   pArc1.setAttribute({visible:false});

   var pArc2 =graph.create('intersection', [circle1, bi2, 0]);
   pArc2.setAttribute({visible:false});

   var pArc3 =graph.create('intersection', [circle2, bi1, 1]);
   pArc3.setAttribute({visible:false});

   var pArc4 =graph.create('intersection', [circle2, bi2, 0]);
   pArc4.setAttribute({visible:false});

   //ARCS
   var arc1 =drawArc(graph, PtB, pArc2, pArc1);
   arc1.setAttribute({visible:()=>i>2, radiusPoint:{visible:false}, anglePoint:{visible:false}});

   var arc2 =drawArc(graph, PtC, pArc4, pArc3);
   arc2.setAttribute({visible:()=>i>1, radiusPoint:{visible:false}, anglePoint:{visible:false}});

   var arc3 =drawArc(graph, PtA, PtC, PtB);
   arc3.setAttribute({visible:()=>i>0, radiusPoint:{visible:false}, anglePoint:{visible:false}});

   var ang1 =drawAngle(graph, PtC, PtA, Int3);
   ang1.setAttribute({name:'', radius:1, visible:()=>i>3,radiusPoint:{visible:()=>i>0}, anglePoint:{visible:()=>i>2}});

   var ang2 =drawAngle(graph, PtB, PtA, Int3);
   ang2.setAttribute({name:'', radius:1, visible:()=>i>3,radiusPoint:{visible:()=>i>0}, anglePoint:{visible:()=>i>2}});
   //labelIt(graph, ang2, ang2.Value().toFixed(0));
    /////////////////////////////////////
    /*
    function movePtB(){
    PtB.moveTo([0.0, 3+Math.round(4*Math.random())],100)};
    */
    var txt0 =placeMiddleText(graph, 0, 4.5, 'You are given angle AOB.');
    txt0.setAttribute({visible:()=>i==0});
    var txt1 =placeMiddleText(graph, 0, 4.5, 'With O as center, draw an arc that cuts both rays.');
    var txt11= placeMiddleText(graph, 0, 3.5, 'The arc cuts ray OA at point 1 and ray OB at point 2');
    txt1.setAttribute({visible:()=>i==1});
    txt11.setAttribute({visible:()=>i==1});
    var txt2 =placeMiddleText(graph, 0, 4.5, 'Next, with point 1 as center, draw an arc in the middle of both rays')
    txt2.setAttribute({visible:()=>i==2});
    var txt3 =placeMiddleText(graph, 0, 4.5, 'Next, with point 2 as center, draw an arc in the middle of both rays');
    txt3.setAttribute({visible:()=>i==3});
    var txt4 =placeMiddleText(graph, 0, 4.5, 'Draw a line joining the point of intersection P with point O.');
    var txt44 =placeMiddleText(graph, 0, 3.5, 'Line OP is the angle bisector of angle AOB.');
    txt4.setAttribute({visible:()=>i==4});
    txt44.setAttribute({visible:()=>i==4});
    ///////////////////////////
    analytics.on('down', function(){
      if(i>0)
      {
      i-=1;
      }
      else
      {
        i=0;
      }
    });
    erase.on('down', function(){
      if(i<4)
      {
      i+=1;
      }
      else
      {
        i=4;
      }
    })
///////////////////////////////////////////////////////////////////////
    graph.unsuspendUpdate();
                      },
              }
export default Boxes;
