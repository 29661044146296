<template>
  <div>
    <h3 ref="intro">
      Goal of the Exercise
    </h3>
    <br>
    <p>
      You are given an angle OAB. The goal is to draw the line that divides this angle into two equal angles (i.e. angle bisector).
    </p>
    <br>
    <h3 ref="pg">
      MagicGraph | Drawing Angle Bisector
    </h3>
    <br>
    <h5> Getting Started </h5>
    <br>
    <p>
      This MagicGraph offers a visual-interactive illustration that demonstrates construction of the bisecor of a given angle.
      <br>
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p>
      Tap on <i class="next ma-1" /> button  to go to the next step. Tap on <i class="previous ma-1" /> button to go to the previous step.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'TriangleArea',
  created () {
    this.$store.commit('navigation/resetState');
    let title = 'Drawing an Angle Bisector';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo: {
          title: 'Drawing an Angle Bisector',
        //  titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {vmid:'description',name: 'description', content: 'Learn interactively about drawing an angle bisector.'}
                ]
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
